.user {
    background-color: $black-bg;
    width: 100%;
    min-height: 100vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;

    @media screen and (max-width : 992px) {
        padding: 10px;
    }

    img {
        max-width: 200px;
        width: 100%;
        padding: 20px 0 0 30px;


        @media screen and (max-width : 992px) {
            padding: 10px;
        }
    }

    .account {
        text-align: center;
        // padding-top: 50px;
        min-height: calc(100vh - 200px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3 {
            color: $grey-100;
            font-size: 28px;
            line-height: 42px;

        }

        p {
            color: $grey-100;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 80px;

        }

        .add-new-pages {
            display: inline-flex;
            border: 1px solid $grey-100;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            padding: 25px 70px;
            text-decoration: none;
            margin-top: 70px;
            height: 100px;
        }

        .plus {
            padding: 0 10px;
        }

        p {
            text-decoration: none;
            color: $grey-100;
            font-size: 20px;
            white-space: nowrap;
            margin-bottom: 0;

        }
    }

    .add-account {
        max-width: 450px;
        width: 100%;
        margin: 0 auto;
        // transform: translateY(-30px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: calc(100vh - 200px);

        @media screen and (max-width : 992px) {
            transform: translateY(0px);
        }

        .nav-tabs {
            border-bottom: none;
            width: 100%;
            display: flex;
            justify-content: space-around;
            padding-bottom: 30px;

            .nav-item {
                .nav-link {
                    background-color: transparent;
                    border: none;
                    color: $grey-100;
                }

                .active {
                    color: $orange-100;
                    border-bottom: 2px solid $orange-100;
                }
            }
        }

        .tab-content {
            width: 100%;

            .tab-pane {
                .user_create_account {
                    .form-select {
                        background-image: url('../../../../public/assets/images/Icon_down.svg');

                    }

                    // .dropdown {
                    //     .btn {
                    //         background-color: $black-300;
                    //         color: $grey-100;
                    //         max-width: 800px;
                    //         width: 100%;
                    //         position: relative;
                    //         text-align: left;
                    //         padding: 10px;

                    //         &:focus {
                    //             box-shadow: none;
                    //         }

                    //         &::after {
                    //             content: '';
                    //             position: absolute;
                    //             right: 0;
                    //             // top: 0;
                    //             width: 30px;
                    //             height: 30px;
                    //             background-image: url('../../../../public/assets/images/Icon_down.svg');
                    //             background-repeat: no-repeat;
                    //             background-position: center;
                    //         }
                    //     }

                    //     .dropdown-menu {
                    //         max-width: 800px;
                    //         width: 100%;
                    //         padding: 0;

                    //         .dropdown-item {
                    //             background-color: $black-300;

                    //             &:hover {
                    //                 background-color: $black-200;
                    //             }
                    //         }
                    //     }
                    // }

                    .pass-word {
                        position: relative;

                        img {
                            position: absolute;
                            top: -3px;
                            width: 50px;
                            right: 20px;

                            @media screen and (max-width :768px) {
                                top: 5px;
                            }
                        }
                    }

                }
            }

            .add-text {
                .add-text-btn {
                    margin-top: 15px;

                    h4 {
                        font-size: 13px;
                        color: $grey-100;
                        margin-bottom: 5px;
                    }

                    p {
                        color: $grey-100;
                        margin-bottom: 0;
                        padding-bottom: 10px;
                        font-size: 13px;
                    }

                }

                .button-list {
                    display: flex;

                    .first {
                        margin-right: 20px;
                        padding: 2px 10px;
                        background-color: $orange-100;
                        border: none;
                        font-size: 13px;
                        color: $grey-100;
                    }

                    .second {
                        @extend .first;
                        background-color: $black-500;
                    }

                    .third {
                        @extend .first;
                        background-color: #926A78;
                    }
                }
            }

            .save {
                text-align: right;
                margin-top: 20px;

                .submit {
                    color: $grey-100;
                    padding-right: 20px;
                    cursor: pointer;
                    background-color: transparent;
                    border: 0;
                }
            }

            .scrolling-text {
                overflow: auto;
                max-height: calc(100vh - 220px);
                position: relative;

                &::-webkit-scrollbar {
                    width: 10px;

                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    background-color: $black-500;

                }

                h4 {
                    color: $blue-100;
                    font-size: 14px;
                    margin: 10px 0;
                }

                .outer-list {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .list-1 {
                        padding: 5px;
                        margin-right: 5px;
                        width: 100%;


                        h3 {
                            color: $white-color;
                            font-size: 17px;
                            margin-bottom: 0;
                        }

                        a {
                            color: $grey-100;
                            text-decoration: none;
                            font-size: 14px;
                        }

                        &:hover {
                            background-color: #3C446D;
                            border-radius: 5px;

                        }
                    }

                    img {
                        color: $blue-100;
                        width: 90px;

                        padding: 0 30px;

                    }

                }
            }

            .new-page {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 40px;
                width: 100%;
                left: 0;

                @media screen and (max-width: 992px) {
                    bottom: -50px;
                }

            }

            .select-button {
                text-align: right;

                button {
                    background-color: transparent;
                    border: 0;
                    color: $grey-100;
                    padding-right: 20px;
                }
            }

        }
    }

    .new-account {
        max-width: 950px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        min-height: calc(100vh - 200px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width : 992px) {
            margin-top: 10px;
            padding-bottom: 30px;
        }

        h3 {
            color: $grey-100;
            font-size: 23px;
            line-height: 42px;
        }

        p {
            margin-bottom: 20px;
            color: $grey-100;
            font-size: 16px;
            line-height: 30px;
        }

        .add-create {
            border: 1px solid $black-500;
            padding: 25px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: $grey-100;
            font-size: 20px;
            margin-bottom: 0;
            height: 100px;
            margin: 7px;

            img {
                padding: 0 10px;
                width: 40px;
            }

        }

    }
}