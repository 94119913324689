.box-inter {
    background-color: $black-500;
    // padding: 12px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    position: relative;
    z-index: 0;
    border-radius: 5px;
    margin: 5px;

    ul {
        list-style: none;
        padding-left: 0;
        text-align: center;
        margin-bottom: 0;

        li {
            font-size: 18px;
            color: $grey-100;
        }
    }

    .img {
        position: absolute;
        top: -14px;
        right: 10px;
        width: 60px;



        @media screen and (max-width : 768px) {
            top: 0;
            width: 50px;
        }

    }
}