.media-page {
    display: flex;
    width: 100%;

    @media screen and (max-width : 992px) {
        flex-wrap: wrap;
    }

    .left_bar_media {
        max-width: 350px;
        width: 100%;
        background-color: $black-500 ;
        min-height: calc(100vh - 110px);
        padding: 60px 38px;
        text-align: center;


        @media screen and (max-width : 992px) {
            max-width: 1140px;
            width: 100%;
            margin-bottom: 10px;
        }



        p {
            color: $grey-600;
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 30px;

        }
    }

    .second-left {
        text-align: left;
        padding: 40px 38px 20px;


        p {
            color: $grey-100;
            font-size: 16px;
            line-height: 25px;
            padding-top: 30px;
            margin-bottom: 10px;
        }

        .web-screen {
            margin-bottom: 30px;
            text-align: center;
            position: relative;

            .input-radio {
                display: none;
            }



            .circle-check {
                width: 15px;
                height: 15px;
                border: 1px solid $grey-100;
                border-radius: 50px;
                display: inline-block;
                position: absolute;
                left: 0;
                margin-top: 40px;
                transition: 0.5s all ease-in-out;

                &::before {
                    content: '';
                    width: 7px;
                    height: 7px;
                    border-radius: 50px;
                    background-color: $blue-100;
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin-left: 3px;
                    margin-top: 3px;
                    transition: 0.5s all ease-in-out;
                    transform: scale(0);
                }
            }

            input {
                margin-right: 20px;

            }

            label {
                img {
                    width: 120px;
                    margin-bottom: 10px;
                    height: 100px;
                }
            }
        }

        .media_pod_create_button {
            text-align: center;

            .create_button {
                width: 46%;
            }
        }
    }

    .web-screen label input:checked+.circle-check::before {
        transform: scale(1);
    }

    .web-screen label input:checked+.circle-check {
        border-color: $blue-100;
    }



    .screen-media {
        background-image: linear-gradient(229deg, #5D3C6D, #C08074);
        width: 100%;
        text-align: center;

        padding: 40px 0px 0px;
        min-height: calc(100vh - 110px);

        @media screen and (max-width : 992px) {
            padding: 40px 20px;
        }


        h3 {
            font-size: 20px;
            color: $grey-100;
            margin-bottom: 40px;
        }

        .box-list-items {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .media-heading {
            margin-top: 22px;

            img {
                width: 150px;

                @media screen and (max-width : 992px) {
                    width: 100%;
                }
            }

            h4 {
                font-size: 18px;
                margin-bottom: 20px;
                color: $grey-100;
                margin-top: 50px;
            }

            h5 {
                border: 1px solid $grey-100;
                width: 40px;
                height: 40px;
                margin-bottom: 0;
                border-radius: 50px;
                margin: 10px auto;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $grey-700;
            }

            .text-3 {
                transform: translateY(45px);

                @media screen and (max-width : 992px) {
                    transform: translateY(0px);
                }
            }
        }

        .outer-media {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 10px;
                top: -50px;
                background-image: url('../../../../public/assets/images/img_playlist_bg_circle.svg');
                background-size: cover;
                background-repeat: no-repeat;
                width: 200px;
                height: 200px;
                rotate: -8deg;

                @media screen and (max-width : 1024px) {
                    display: none;
                }
            }


        }
    }
}

.restaurant-food-media {
    background-color: $black-500;
    width: 100%;
    margin: 20px;
    border-radius: 20px;
    position: relative;
    height: calc(100vh - 150px);

    @media screen and (max-width :992px) {
        border-radius: 0;
        margin: 10px 0;
        min-height: 100vh;
    }

    .card-food-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px 0;

        .food-1 {
            span {
                margin-bottom: 0;
                font-size: 20px;
                color: $grey-100;
                line-height: 25px;
                padding-right: 10px;

                @media screen and (max-width :568px) {
                    font-size: 14px;
                }
            }

            img {
                width: 14px;
            }
        }

        .food-2 {

            img {
                @media screen and (max-width :568px) {
                    width: 13px;
                }
            }

            span {
                margin-bottom: 0;
                padding: 0 14px;
                font-size: 16px;
                color: $blue-100;

                @media screen and (max-width :568px) {
                    font-size: 13px;
                    padding: 0 5px;
                }


            }



            .food-color {
                color: $coral-100;
            }
        }
    }

    .box-scroll {
        overflow: auto;
        padding: 10px 20px 40px;
        height: calc(100vh - 290px);

        &::-webkit-scrollbar {
            width: 5px;


        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $grey-500;

        }

        p {
            color: $grey-100;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 25px;
            margin-top: 10px;

        }

        .select-food {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 22px;

            span {
                margin-bottom: 0;
                font-size: 16px;
                line-height: 19px;
                color: $grey-100;
                padding-left: 10px;
            }

            .dropdown {
                .btn {
                    color: $grey-100;
                    padding: 0;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .select-btn {
                    img {
                        width: 50px;
                    }


                }

                .dot {
                    img {
                        transform: translateX(10px);
                    }
                }

                .menu-list {
                    .dropdown-item {
                        border-bottom: 1px solid #e4e4ed5b;

                    }


                }

                .dropdown-menu {

                    .dropdown-item {

                        padding: 10px 10px;


                        img {
                            margin-right: 15px;
                        }


                    }


                }
            }

        }

        .box-border {
            border: 2px dashed $grey-500;
            margin-top: 10px;
            // padding: 140px;
            min-height: calc(100vh - 400px);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            h5 {
                font-size: 14px;
                color: $white-color;
                line-height: 25px;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;
                margin-top: 0;
                text-align: center;
                font-size: 16px;
                line-height: 25px;
                color: $grey-100;
            }
        }

        .table {
            margin-top: 20px;

            tbody {
                tr {
                    &:hover {
                        background-color: $blue-500;
                    }

                    td {
                        color: $grey-100;
                        font-size: 14px;
                        padding: 12px;
                        border-bottom: 1px solid $black-800;
                        width: 55%;

                        img {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .box-setting {
            padding: 23px 0;
            min-height: calc(100vh - 700px);
        }
    }

    .add-screen {
        text-decoration: none;
        padding-left: 20px;

        span {
            color: $orange-100;
            font-size: 14px;
            line-height: 25px;
            padding-left: 10px;

        }
    }


    .food-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 20px;
        width: 94%;
        right: 15px;


        p {
            color: $grey-100;
            margin-bottom: 0;
            font-size: 17px;
        }
    }

}

.food_right_bar {
    background-color: $black-300;
    max-width: 400px;
    width: 100%;
    padding: 20px 20px 10px;

    @media screen and (max-width: 992px) {
        display: block;
        max-width: 1140px;
        width: 100%;
    }

    .upload-text {
        display: flex;
        align-items: center;

        h3 {
            margin-bottom: 0;
            margin-left: 10px;
            font-size: 20px;
            line-height: 22px;
            color: $grey-100;
        }
    }

    .menu_list_food {
        ul {
            list-style: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            margin-bottom: 0;

            li {
                padding: 5px 14px;

                @media screen and (max-width:568px) {
                    padding: 5px 7px;
                }



                a {
                    text-decoration: none;
                    color: $grey-100;
                    font-size: 16px;
                    line-height: 19px;

                    &:hover {
                        color: $orange-100;
                    }

                    &.active {
                        border-bottom: 2px solid $orange-100;
                        color: $orange-100;
                    }
                }



            }


        }
    }

    .list-food-menu {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;

        p {
            margin-bottom: 0;
            color: $grey-100;
            font-size: 12px;
        }

        .food_list {
            display: flex;
            align-items: center;




            .dropdown {

                .btn {
                    color: $grey-100;
                    padding: 0;

                    &:focus {
                        box-shadow: none;
                    }

                    span {
                        margin-bottom: 0;
                        color: $orange-100;
                        font-size: 12px;
                    }
                }


            }


        }
    }

    .table {
        tbody {
            tr {
                td {
                    color: $grey-100;
                    padding: 7px 20px;
                    cursor: pointer;
                    border-bottom: 1px solid $black-800;

                    img {
                        width: 14px;
                        margin-right: 10px;
                    }


                }


            }
        }
    }
}

.left-bar-media {
    max-width: 310px;
    width: 100%;
    background-color: $black-500 ;
    min-height: calc(100vh - 110px);
    padding: 30px 10px;
    position: relative;

    @media screen and (max-width : 992px) {
        max-width: 1140px;
        width: 100%;
        margin-bottom: 10px;
    }

    .search-icon {
        position: relative;

        img {
            position: absolute;
            top: 8px;
            right: 10px;
        }
    }

    .media-outer-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        p {
            color: $grey-100;
            font-size: 14px;
            line-height: 25px;
            margin-bottom: 0;

        }

        .media-from {
            display: flex;
            align-items: center;

            span {
                padding: 0 5px;
                font-size: 12px;
                color: $grey-100;
            }

            .first-media {
                color: $orange-100;
            }

            .dropdown {

                .btn {
                    color: $grey-100;
                    padding: 0;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .dropdown-toggle {
                    &::after {
                        vertical-align: 0.10em;
                    }
                }


            }

        }


    }

    .create-btn {


        position: absolute;
        bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 93%;

    }

    .table {

        thead {


            tr {
                th {
                    color: $grey-100;
                    font-weight: 100px;
                    font-size: 14px;
                    font-family: 'Poppins-Regular';
                    font-weight: 300;
                    border-bottom: 1px solid $black-800 !important;
                }
            }
        }

        tbody {
            border-bottom: 1px solid $black-800;

            tr {


                &:hover {
                    background-color: $blue-500;
                }

                td {
                    padding: 15px 10px;
                    color: $grey-100;
                    font-size: 14px;


                }
            }
        }
    }
}

.dropdown {
    .btn {
        border: 0;
    }

    .dropdown-menu {
        background-color: $black-bg;
        border-radius: 7px;
        padding: 0;

        .radius-top {
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
        }

        .radius-bottom {
            border-bottom-right-radius: 7px;
            border-bottom-left-radius: 7px;
            border-bottom: 0;
        }

        .dropdown-item {
            font-size: 16px;
            color: $white-color;
            padding: 5px 10px;
            background-color: $black-bg;

            &:hover {
                background-color: $blue-500;
            }
        }
    }
}