custom-button {
    display: inline-block;
    border: 0;
    border-radius: 5px;
    padding: 8px 30px;
    outline: 0;
    box-shadow: none;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;

    @media screen and (max-width : 1299px) {
        font-size: 13px;
        white-space: nowrap;
    }
}

.theme-button {
    @extend custom-button;
    background-color: var(--grey-200);
    color: var(--black-300);

    &:hover {
        color: var(--black-300);
    }
}

.theme-button-2 {
    @extend custom-button;
    color: var(--white);
    background-color: var(--orange-100);
    // padding: 11px 40px;

    &:hover {
        color: var(--white);
    }
}



.theme-3 {
    @extend custom-button;
    border: 1px solid var(--orange-100);
    color: var(--white);
    background-color: transparent;
    // padding: 8px 18px;

    &:hover {
        color: #fff;
    }
}

.theme-cold {
    @extend .theme-3;
    background-color: var(--blue-500);
    border: 0;
    padding: 10px 20px;
}

.theme-4 {
    @extend .theme-3;
    background-color: var(--coral-100);
    border: 0;
    // padding: 10px 40px;

}

.theme-button-1 {
    @extend .theme-button-2;
    padding: 10px 13px;
}