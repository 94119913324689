.total-box {
    background-color: $black-500;
    border-radius: 15px;
    cursor: pointer;

    &:focus-within {
        background-color: $blue-500;
    }

    &:hover {
        background-color: $blue-500;
    }

    a {
        text-decoration: none;
    }

    @media screen and (max-width : 768px) {
        border-radius: 0px;
    }

    p {
        padding: 20px 20px 0;
        color: $grey-100;
        font-size: 20px;
        margin-bottom: 0;
    }

    .center {
        padding: 40px 130px 50px 130px;
        text-align: center;

        @media screen and (max-width : 1199px) {
            padding: 30px;
        }

        h4 {
            font-size: 42px;
            color: $grey-100;
            font-weight: 600;
            line-height: 50px;
            margin-bottom: 0;
        }
    }
}

.outer {
    padding: 50px;
    flex: 1;
    width: calc(100% - 400px);
    place-self: center;

    @media screen and (max-width: 992px) {
        padding: 0px;
        width: calc(100% - 0px);
    }
}

.right-bar {
    max-width: 400px;
    min-height: calc(100vh - 111px);
    background-color: $black-500;
    padding: 20px 20px 20px 10px;
    overflow: hidden;


    @media screen and (max-width : 992px) {
        max-width: 1140px;
        width: 100%;
        min-height: 100%;
        padding: 30px 20px;
        margin-bottom: 10px;
    }

    &.sm {
        max-width: 300px;
    }

    .color {


        h3 {
            color: $grey-100;
            font-size: 20px;
            line-height: 25px;

        }

        a {
            font-size: 16px;
            color: $blue-100;
            line-height: 25px;
            display: block;
        }
    }

}