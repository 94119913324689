.Screens_empty {
    background-image: linear-gradient(229deg, #5D3C6D, #C08074);
    width: 100%;
    min-height: calc(100vh - 110px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        font-size: 16px;
        color: $grey-100;
        line-height: 25px;
        margin-bottom: 40px;
    }

    img {
        margin-top: 60px;
    }
}

.screen-front {
    width: 100%;
    min-height: 100%;
    display: flex;

    @media screen and (max-width :992px) {
        flex-wrap: wrap;
    }
}

.screens {
    background-color: $black-300;
    padding: 20px 0;
    width: 100%;
    min-height: calc(100vh - 150px);
    border-radius: 15px;
    margin: 20px;

    @media screen and (max-width :992px) {
        margin: 0px;
        border-radius: 0px;
    }

    .screen_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;

        h3 {
            font-size: 20px;
            color: $grey-100;
            line-height: 30px;
            margin-bottom: 0;
        }

        .screen_text_option {
            display: flex;
            align-items: center;
            gap: 10px;

            span {
                font-size: 12px;
                color: $grey-100;
                line-height: 18px;
            }

            .screen_program_text {
                color: $orange-100;
            }

            .dropdown {
                .btn {
                    color: $white-color;
                    font-size: 16px;
                    padding: 0;


                    &:focus {
                        box-shadow: none;
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    background-color: $black-bg;
                    border-radius: 7px;

                    .radius-top {
                        border-top-right-radius: 7px;
                        border-top-left-radius: 7px;
                    }

                    .radius-bottom {
                        border-bottom-right-radius: 7px;
                        border-bottom-left-radius: 7px;
                    }

                    .dropdown-item {
                        color: $grey-100;
                        padding: 10px 20px;
                        background-color: $black-bg;

                        &:hover {
                            background-color: $blue-500;
                        }
                    }
                }

            }

            span {
                color: $grey-100;
                margin-bottom: 0;
            }
        }
    }

    .screen-scroll {
        // height: 380px;
        // overflow-y: scroll;

        height: calc(100vh - 220px);
        overflow: auto;

        @media screen and (max-width :768px) {
            height: 480px;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $grey-500;

        }

        .screen_gradient {

            h3 {

                color: $grey-100;
                font-size: 16px;
                margin-bottom: 0;
                background-image: linear-gradient(90deg, #3F71AC, #627AEC00);
                margin: 10px 0;
                padding: 7px 20px;

            }
        }

        .check_button_screen {
            padding: 0 20px;
            text-align: center;

            .start-btn {
                background-color: $blue-500;
                min-height: 109px;
                padding: 10px;
                border: 2px solid $black-bg;
                outline: 4px solid $blue-500;
                border-radius: 2px;
                position: relative;
                margin-top: 17px;

                &:focus-within {
                    border: 2px solid $blue-100;
                }

                .screen-images {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .start {
                        img {
                            background-color: $white-color;
                            padding: 6px;
                            border-radius: 50px;
                        }
                    }
                }

                p {
                    margin-bottom: 0;
                    color: $grey-100;
                    font-size: 14px;
                    text-align: left;
                    position: absolute;
                    bottom: 15px;
                }
            }
        }

        h3 {
            font-size: 16px;
            margin-bottom: 0;
            line-height: 25px;
            color: $grey-100;
            margin-top: 5px;
        }

        p {
            color: $grey-900;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 0;
        }
    }
}

// .modal-content {
//     background-color: transparent !important;
//     border: 0 !important;
// }

.content-modal {
    background-color: $black-300;
    border-radius: 5px;

    .form-bg-dark {
        input {
            font-size: 16px;

        }

        ::placeholder {
            font-size: 16px;
        }
    }
}

// right bar scss start

.right_list {
    background-color: $black-300;
    max-width: 440px;
    width: 100%;
    // padding: 15px;
    position: relative;
    height: calc(100vh - 110px);

    @media screen and (max-width :992px) {
        max-width: 1140px;
        width: 100%;
        margin: 10px 0;
        height: 100vh;
    }

    .screen-tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 15px;

        .front_tabs {
            span {
                color: $grey-100;
                font-size: 20px;
                line-height: 25px;
                margin-left: 10px;
            }
        }

        .power {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            cursor: pointer;


            .wifi-net {
                background-color: transparent;
            }

            span {
                color: $blue-100;
                font-size: 16px;
                line-height: 25px;

            }

            img {
                background-color: $white-color;
                padding: 7px;
                border-radius: 50px;
                height: 35px;
                width: 35px;


            }

        }


    }

    .tab-scroll {
        // height: 360px;
        // overflow-y: scroll;
        height: calc(100vh - 235px);
        overflow: auto;
        padding: 0 15px;

        @media screen and (max-width : 568px) {
            height: 490px;
        }

        &::-webkit-scrollbar {
            width: 6px;

        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $grey-500;

        }

        .nav-tabs {
            border-bottom: 0;
            background-color: $dark-grey-300;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            flex-wrap: nowrap;
            border-radius: 4px;

            .active {
                background-color: $orange-100;
                border: 0;
                border-radius: 0;
                width: 50%;
                border-radius: 4px;

                &:focus {
                    border-color: transparent;

                }

            }

            .nav-link {
                padding: 4px 59px;
                color: $white-color;
                margin-bottom: 0;
                border: 0;
                font-size: 16px;
                line-height: 25px;

                @media screen and (max-width: 768px) {
                    padding: 4px 30px;
                }

            }
        }

        .tab-content {

            h3 {
                font-size: 14px;
                color: $white-color;
                line-height: 25px;
                margin-bottom: 0;
            }

            .first_tab_list {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;


                .tab-screen {
                    position: relative;

                    .label-check {
                        font-size: 16px;
                        color: $white-color;
                        margin-right: 0px;
                        align-items: center;
                        padding-left: 40px;

                        @media screen and (max-width :768px) {
                            padding-left: 20px;
                        }

                    }

                    .input-radio {
                        display: none;
                    }

                    .circle-check {
                        width: 15px;
                        height: 15px;
                        border: 1px solid $grey-100;
                        border-radius: 50px;
                        display: inline;
                        position: absolute;
                        top: 5px;
                        margin-left: -20px;
                        transition: 0.5s all ease-in-out;

                        @media screen and (max-width :768px) {
                            margin-left: -15px;
                        }

                        &::before {
                            content: '';
                            width: 7px;
                            height: 7px;
                            border-radius: 50px;
                            background-color: $blue-100;
                            position: absolute;
                            left: 0;
                            top: 0;
                            margin-left: 3px;
                            margin-top: 3px;
                            transition: 0.2s all ease-in-out;
                            transform: scale(0);
                        }

                    }
                }

                .tab-screen label input:checked+.circle-check::before {
                    transform: scale(1);
                }

                .tab-screen label input:checked+.circle-check {
                    border-color: $blue-100;
                }

                .hours-option {
                    place-self: flex-start;
                }

                .form-select {
                    // padding-left: 70px;
                    padding-right: 30px;
                    background-image: url('../../../../public/assets/images/Icon_down.svg');
                    background-position: right;
                    font-size: 14px;
                    margin-bottom: 0;
                    width: 210px;
                    text-align: end;

                }

                .form-2 {
                    padding-left: 0;
                    padding-right: 30px;
                }

                .form-3 {
                    padding-left: 5px;
                    padding-right: 0px;
                    text-align: left;
                    color: $white-color;
                    text-align: end;
                    padding-right: 30px;
                }

                .tab_text {
                    span {
                        color: $grey-100;
                        font-size: 16px;

                    }

                    img {
                        width: 18px;
                        padding-left: 5px;
                    }
                }

                input {
                    width: 210px;
                }

                .check_box {
                    position: relative;
                    max-width: 210px;
                    width: 100%;

                    .form-check-input {
                        width: 20px;
                        height: 20px;
                        border-radius: 2px;
                        background-color: transparent;
                        border-color: $dark-grey-300;
                        margin-right: 5px;

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    .form-check-label {
                        color: $grey-100;
                        font-size: 14px;
                        line-height: 21px;
                        padding: 4px;
                    }

                    .star-end {
                        p {
                            margin-bottom: 0;
                            color: $grey-100;
                            font-size: 14px;
                            padding-bottom: 10px;

                            span {
                                text-decoration: underline;
                            }
                        }

                        a {
                            text-decoration: none;
                            color: $orange-100;
                            font-size: 14px;

                            img {
                                padding-right: 10px;
                            }
                        }
                    }
                }
            }
        }

        .digital-lock {
            background-color: $black-400;
            border: 1px solid $grey-100;
            border-radius: 5px;
            padding: 15px 20px;
            margin-top: 10px;

            p {
                color: $grey-100;
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 0;
            }

            .code {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;

                p {
                    background-color: $white-color;
                    height: 50px;
                    width: 30px;
                    color: $black-300;
                    margin-bottom: 0;
                    border: 1px solid $blue-100;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }


        }

        .code-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;

            h3 {
                color: $white-color;
                font-size: 15px;
                line-height: 25px;
            }
        }
    }

    .screen-save-edit-btn {
        position: absolute;
        bottom: 30px;
        right: 20px;
    }
}