.wrapper {
    background-color: $black-bg;
    width: 100%;
    min-height: 100vh;



    .wrapper-body {
        display: flex;
        // align-items: center;
        justify-content: space-between;

        @media screen and (max-width :992px) {
            flex-wrap: wrap;
            padding: 10px;

        }
    }
}