.schedules {
    background-image: linear-gradient(229deg, #5D3C6D, #C08074);
    width: 100%;
    text-align: center;
    padding: 30px 0;
    min-height: calc(100vh - 140px);
    display: flex;
    align-items: center;
    justify-content: center;

    .outer-schedules-text {
        p {
            color: $grey-100;
            font-size: 16px;
            line-height: 25px;
        }

        .schedule-img {
            padding-left: 130px;

            @media screen and (max-width : 992px) {
                padding-left: 0;
            }
        }

    }



}

.modal-content {
    // background-color: $black-300;
    border: 1px solid $grey-100;
    border-radius: 10px;
    text-align: left;

    .modal-header {
        border-bottom: 0;
        // margin-bottom: 25px;

    }

    .modal-footer {
        border-top: 0;
        justify-content: left;
        flex-wrap: nowrap;
        margin-top: 16px;

        .cancel-btn {
            width: 50%;
            text-align: center;
            margin-right: 10px;

        }

        .create-btn-2 {
            width: 49%;
            text-align: center;
        }
    }
}

// .modal-backdrop {
//     position: relative;
//     background-color: transparent;
// }

.Schedules-restaurant-food {
    background-color: $black-300;
    width: 100%;
    margin: 20px;
    border-radius: 15px;
    height: calc(100vh - 150px);
    overflow: hidden;


    @media screen and (max-width: 992px) {
        margin: 20px 0;
        border-radius: 0px;
        padding-bottom: 20px;
    }

    .outer-restaurant-food {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        // margin-bottom: 20px;
        padding: 15px 20px;

        .list_food {
            display: flex;
            cursor: pointer;


            .date {
                font-size: 20px;
                color: $grey-100;
                line-height: 25px;
                margin-bottom: 0;
                margin-right: 10px;
            }

            .fa {
                background-color: $blue-500;
                padding: 4px 10px;
                color: $white-color;
                border-radius: 20px 0px 0px 20px;
                box-shadow: 4px 4px 10px inset $blue-500;
                font-size: 19px;
            }

            .today {
                background-color: $blue-500;
                font-size: 12px;
                color: $white-color;
                padding: 4px 10px;
                margin: 0 3px;
                box-shadow: 4px 4px 10px inset $blue-500;

            }

            .fa_2 {
                border-radius: 0px 20px 20px 0px;
                box-shadow: 4px 4px 10px inset $blue-500;
            }

            .food-next {
                // display: flex;
                cursor: pointer;


            }
        }

        .Schedules-select {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            @media screen and (max-width :992px) {
                width: 100%;
                margin-top: 10px;
            }

            .add-new {
                color: $blue-100;

                font-size: 16px;
                white-space: nowrap;


                @media screen and (max-width :768px) {
                    font-size: 19px;
                }
            }

            .new-delete {
                color: $coral-100;

                font-size: 16px;
                padding-right: 20px;

                @media screen and (max-width :768px) {
                    font-size: 19px;
                }
            }

            .dropdown {
                .new-program {
                    background-color: $black-bg;
                    color: $white-color;
                    padding: 8px 80px 8px 10px;
                    background-image: url('../../../../public/assets/images/Icon_down.svg');
                    background-repeat: no-repeat;
                    background-position: right;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .dropdown-menu {
                    background-color: #2a2f43;
                    padding: 0;
                    width: 100%;
                    -moz-width: 100%;
                    -o-width: 100%;
                    -webkit-width: 100%;
                    border: 1px solid $black-800;

                    .dropdown-item {
                        color: $grey-100;
                        padding: 0;
                        background-color: #2a2f43;
                        border-bottom-left-radius: 7px;
                        border-bottom-right-radius: 7px;

                        .form-bg-dark {
                            position: relative;


                            input {
                                margin-bottom: 0;
                                border-bottom-left-radius: 0;
                                border-bottom-right-radius: 0;
                                padding: 6px 20px 6px 10px;
                            }

                            img {
                                position: absolute;
                                top: 7px;
                                right: 15px;
                            }
                        }

                        &:hover {

                            background-color: transparent;
                        }

                        .program-edit {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 10px 10px;

                            &:hover {

                                background-color: $blue-500;
                            }


                            p {
                                margin-bottom: 0;

                            }

                            img {
                                width: 13px;
                            }
                        }
                    }

                    .food-items {
                        &:hover {
                            border-bottom-left-radius: 7px;
                            border-bottom-right-radius: 7px;
                        }
                    }

                }

            }


        }
    }

    .table_list {
        padding: 10px 15px;
        height: calc(100vh - 200px);
        overflow: auto;
        padding-bottom: 30px;

        @media screen and (max-width : 992px) {
            padding-bottom: 100px;
        }


        &::-webkit-scrollbar {
            width: 5px;

        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $grey-500;

        }


        .table {
            border: 0;
            white-space: nowrap;

            thead {
                border: 0;

                tr {

                    th {
                        border: 0;
                        text-align: center;
                        color: $grey-100;
                        font-family: 'Poppins-Regular';
                        font-weight: 300;


                        cursor: pointer;

                        &:hover {
                            background-image: linear-gradient(#4372B3, #5F7AE6);
                            border-radius: 50px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        border: 1px solid $black-800;
                        padding: 20px 50px;

                        .bi {
                            font-size: 40px;
                            color: $blue-100;
                        }

                        .btn-group {
                            background-color: $blue-500;
                            width: 10px;

                            .right-click {
                                color: $grey-100;
                                text-decoration: none;
                                font-size: 14px;
                                line-height: 25px;
                                text-align: left;
                            }


                        }

                    }

                    .timing {
                        border: 0;
                        text-align: end;
                        color: $grey-100;
                        transform: translateY(-10px);
                        padding: 5px;
                    }
                }
            }
        }
    }


}