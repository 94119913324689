custom-input {
    width: 100%;
    background-color: $black-300;
    border-radius: 5px;
    padding: 10px 16px;
    border: 1px solid transparent;
    box-shadow: none;
    outline: 0;
    margin-bottom: 15px;


    &:focus {
        box-shadow: none;
        border: 1px solid $grey-100;
        background-color: $black-300;
        color: var(--white);
    }
}

.theme-input-dark {
    @extend custom-input;
    color: var(--white);
    background-color: $black-bg;
}

.theme-input {
    @extend custom-input;
    color: var(--white);
}

.theme-input-1 {
    @extend custom-input;
    background-color: $black-500;
    padding: 5px;
    margin-bottom: 0;

    &:focus {
        background-color: $black-500;
        border: 1px solid $black-500;

    }
}

.label-text {
    color: $grey-100;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
}

.form-bg-dark {

    input,
    textarea,
    select {
        @extend .theme-input-dark;
        padding: 7px;

    }

    label {
        @extend .label-text;
    }
}


.form-dark {

    input,
    textarea,
    select {
        @extend .theme-input;
    }

    label {
        @extend .label-text;
    }
}