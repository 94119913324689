.left-side {
    max-width: 170px;
    width: 100%;
    min-height: calc(100vh - 113px);
    background-color: $black-500;
    // place-self: flex-start;


    @media screen and (max-width : 992px) {
        display: none;
    }

    ul {
        list-style: none;
        padding: 20px 11px;

        .bor {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: -10px;
                top: 10px;
                width: 3px;
                height: 40px;
                background-color: $orange-100;
            }
        }

        li {
            padding: 15px 10px;

            .active-1 {
                color: $orange-100;
            }


            img {
                padding-right: 10px;
            }

            a {
                text-decoration: none;
                color: $grey-500;
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
}