.sign-from {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .Welcome_page {
        background-image: url('../../../../public/assets/images/signup_bg.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        width: 50%;
        min-height: 100vh;
        padding: 0px 90px;


        @media screen and (max-width : 992px) {
            padding: 30px 20px;
            width: 100%;
        }

        .welcome {
            max-width: 500px;
            width: 100%;
            min-height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: start;
            justify-content: center;
            flex-direction: column;

            img {
                max-width: 60px;
                width: 100%;
                border-radius: 5px;
                margin-bottom: 30px;

            }

            h1 {
                font-size: 28px;
                text-align: left;
                color: $grey-100;
                margin-bottom: 10px;
            }

            .outer-text {
                font-size: 17px;
                color: $grey-100;
                margin-bottom: 25px;
            }

            h3 {
                font-size: 18px;
                color: $grey-100;
                font-weight: 500;
                margin-bottom: 30px;
            }

            .context {
                font-size: 14px;
                color: $grey-100;
                margin-bottom: 15px;
            }

            .btn-group {
                .btn {
                    &:focus {
                        box-shadow: none;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                        margin-bottom: 0;
                        margin-right: 10px;
                    }

                    color: $grey-100;
                    font-size: 16px;
                    border-bottom: 1px solid $grey-100;
                    border-radius: 0;
                    padding: 0;
                }

                .dropdown-menu {

                    padding: 0;

                    .dropdown-item {
                        background-color: $black-200;
                        padding: 5px 20px;
                        font-size: 16px;

                        img {
                            width: 20px;
                            height: 20px;
                            margin-bottom: 0;
                            margin-right: 10px;
                        }

                        &:hover {
                            background-color: $black-500;
                        }
                    }
                }
            }

            // .form-select {
            //     max-width: 150px;
            //     width: 100%;
            //     background-color: transparent;
            //     border: none;
            //     border-bottom: 2px solid $grey-100;
            //     color: $grey-100;
            //     font-size: 14px;
            //     padding: 2px 20px;
            //     border-radius: 0;
            //     margin-top: 40px;
            //     appearance: none;
            //     -moz-appearance: none;
            //     -webkit-appearance: none;
            //     background-image: url('../../../../public/assets/images/Icon_down2.svg');

            //     &:focus {
            //         box-shadow: none;
            //         border-radius: 0;
            //     }

            //     option {
            //         background-color: $black-200;

            //     }
            // }
        }
    }

    .sign-in {
        background-color: $black-bg;
        width: 50%;
        padding: 40px 90px;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;

        @media screen and (max-width: 992px) {
            width: 100%;
            padding: 40px 20px;
        }

        .tabs-items {
            position: absolute;
            width: 100%;
            left: 0;
            top: 40px;
        }

        .nav-tabs {
            border-bottom: none;
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            flex-wrap: nowrap;

            .nav-item {
                padding: 0 30px;


                @media screen and (max-width :992px) {
                    padding: 0 0px;
                }

                .nav-link {
                    background-color: transparent;
                    border: none;
                    color: $dark-grey-300;
                    font-size: 20px;
                    line-height: 30px;
                    white-space: nowrap;
                }

                .active {
                    color: $orange-100;
                    border-bottom: 2px solid $orange-100;
                }
            }
        }

        .tab-content {
            transform: translateY(20px);

            .tab-pane {
                .button-submit {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    // flex-wrap: wrap;
                    margin-top: 30px;

                    p {
                        color: $grey-100;
                        padding: 0 12px;
                        margin-bottom: 0;
                        font-size: 10px;
                        margin-bottom: 0;
                        font-size: 16px;
                        white-space: nowrap;

                        @media screen and (max-width: 992px) {
                            padding: 5px;
                        }

                    }

                    .google {
                        width: 45%;
                        white-space: nowrap;
                        text-align: center;


                        img {
                            max-width: 24px;
                            width: 100%;
                            padding-right: 5px;

                        }


                    }

                    .account_button_click {
                        width: 45%;
                    }

                    @media screen and (max-width: 1192px) {
                        .theme-3 {
                            padding: 6px 10px;
                        }

                        .theme-button-2 {
                            padding: 10px 13px;
                        }

                        span {
                            font-size: 10px;
                        }

                        img {
                            max-width: 20px;
                            width: 100%;
                        }

                        p {
                            font-size: 10px;
                        }
                    }


                }

                .from {
                    // margin: 51px 0;

                    .pass-word {
                        position: relative;

                        img {
                            position: absolute;
                            top: 16px;
                            width: 24px;
                            right: 20px;


                        }
                    }

                }
            }
        }

        .forgat_back_page {


            .back-page {
                color: $orange-100;
                font-size: 20px;
                text-decoration: none;
                display: flex;
                align-items: center;


                img {
                    font-size: 30px;
                }
            }

            .sing-outer-t {
                color: $grey-100;
                padding: 20px 0 40px;
                font-size: 16px;
                margin-bottom: 0;
                line-height: 28px;
            }

            .button-1 {
                text-align: right;

                .theme-button-2 {
                    margin-top: 17px;
                }
            }
        }

        .button-1 {
            text-align: right;

            a {
                &:hover {
                    color: $grey-100;
                }
            }


        }

        .reset-pass {

            color: var(--orange-100);
            font-size: 20px;
            margin-bottom: 0;
            padding-bottom: 83px;
            line-height: 30px;
        }

        .from {
            .pass-word {
                position: relative;

                img {
                    position: absolute;
                    top: 15px;
                    width: 24px;
                    right: 20px;
                }
            }

        }

        .from {
            p {
                font-size: 14px;
                color: $coral-100;
                line-height: 25px;
            }
        }

        .check-out {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 100px;

            .form-check-input {
                background-color: $black-300;
                border: 1px solid $grey-100;
                border-radius: 0;

                &:focus {
                    box-shadow: none;
                    background-color: $black-300;

                }
            }

            label {
                color: $grey-100;
                padding-left: 10px;
                font-size: 15px;
            }

            a {
                color: $coral-100;
                font-size: 16px;
                line-height: 25px;
                cursor: pointer;
                text-decoration: none;
            }
        }

    }
}