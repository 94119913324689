@font-face {
    font-family: 'Poppins-Medium';
    src: url(../../../public/assets/font/Poppins-Medium.ttf);
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url(../../../public/assets/font/Poppins-Regular.ttf);
}

@font-face {
    font-family: 'Poppins-Black';
    src: url(../../../public/assets/font/Poppins-Black.ttf);
}