@import './components/index.scss';
@import './layouts/index.scss';
@import './pages/index.scss';

* {
    margin: 0;
    padding: 0;
    box-shadow: none;
}

body {
    font-family: 'Poppins-Regular' !important;
    overflow-x: hidden;
}