.card-list {
    background-color: $black-500;
    border-radius: 20px;
    // padding: 20px;
    width: 100%;
    height: calc(100vh - 140px);
    overflow: hidden;
    margin: 13px;
    box-sizing: border-box;
    position: relative;

    &::-webkit-scrollbar {
        width: 6px;

    }

    // &::-webkit-scrollbar-thumb {
    //     border-radius: 5px;
    //     background-color: $grey-500;

    // }

    @media screen and (max-width: 992px) {
        margin: 0px;
        border-radius: 0px;
        min-height: calc(100vh - 153px);
    }

    .outer-contact {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        // flex-wrap: wrap;
        padding-bottom: 20px;
        gap: 10px;
        padding: 20px;

        .outer-pictures {
            display: flex;
            gap: 20px;

            h3 {
                &.heading {
                    border-bottom: 1px solid $grey-100;
                }
            }

            img {
                rotate: 270deg;
            }
        }


        h3 {
            color: $grey-100;
            font-size: 20px;
            margin-bottom: 0;

            @media screen and (max-width : 768px) {
                font-size: 17px;
            }
        }

        .card-icon {
            right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            font-size: 12px;

            @media screen and (max-width : 768px) {
                gap: 10px;
            }

            span {
                color: $grey-100;
            }

            .align-icon {
                padding-right: 20px;

                @media screen and (max-width : 768px) {
                    padding-right: 0;
                }
            }


            .align {
                color: $orange-100 !important;

            }

            .fa {

                font-size: 15px;
                color: $grey-100;
            }

            .dropdown {
                .btn {
                    color: $grey-100;
                    padding: 0;
                    border: 0;

                    &:focus {
                        box-shadow: none;

                    }

                    span {
                        color: $orange-100;
                        font-size: 13px;
                        padding: 0;
                    }

                }

                .dropdown-toggle {
                    &::after {
                        vertical-align: 0.150em;
                    }
                }

                .dropdown-menu {
                    padding: 0;

                    border-radius: 20px;

                    .bounder-top {
                        border-top-right-radius: 7px;
                        border-top-left-radius: 7px;
                    }

                    .bounder-bottom {
                        border-bottom-right-radius: 7px;
                        border-bottom-left-radius: 7px;
                    }

                    .dropdown-item {
                        background-color: $black-bg;
                        color: $white-color;



                        &:hover {
                            color: $grey-100;
                            background-color: $blue-500;
                        }
                    }
                }
            }

        }
    }

    .form-check {
        padding-left: 42px;
    }

    .list-table {
        width: 100%;
        height: calc(100% - 30px);
        overflow: auto;
        padding: 20px;
        padding-bottom: 40px;

        &::-webkit-scrollbar {
            width: 6px;

        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $grey-500;

        }

        .table {
            cursor: pointer;

            thead {
                .first-child {
                    color: $grey-100;

                    th {
                        padding: 0;
                        font-size: 15px;
                        font-weight: 100;
                        opacity: 0.9;
                        border-bottom: 0px;
                        padding-left: 20px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        color: $grey-100;
                        padding: 15px 0;
                        font-size: 14px;
                        font-weight: 100;
                        opacity: 0.9;
                        line-height: 21px;
                        border-bottom: 1px solid $black-800 !important;
                        padding-left: 20px;
                        white-space: nowrap;

                        a {
                            text-decoration: none;
                            color: $grey-100;
                        }
                    }
                }


            }

        }
    }






    .form-check {
        .form-check-input {
            background-color: transparent;
            border-radius: 0;
            border: 1px solid $grey-500;

            &:focus {
                box-shadow: none;
            }
        }

        .form-check-label {
            font-size: 14px;
            color: $grey-100;
            line-height: 21px;
        }
    }

    .file-select {
        border-bottom: 1px solid $grey-500;

        .file-1 {
            text-align: center;
            margin: 30px 20px 12px;
            padding: 10px 5px;
            box-sizing: border-box;
            border-radius: 5px;
            cursor: pointer;

            img {
                max-width: 50px;
                width: 100%;

            }

            &:focus-within {
                border: 1px solid $blue-100;
                margin-bottom: 10px;
                padding: 9px 5px;
            }

        }




        .form-check-input {
            background-color: transparent;
            border-radius: 0;
            border: 1px solid $grey-500;
            margin-top: 20px;

            &:focus {
                box-shadow: none;
            }
        }

        p {
            font-size: 16px;
            margin-bottom: 0;
            color: $grey-100;
            line-height: 25px;

            .bi {
                padding-left: 5px;
                color: $blue-100;
            }
        }
    }

    .file-images {
        text-align: center;
        margin-top: 30px;

        img {
            width: 50px;
            margin-bottom: 10px;
        }

        h6 {
            margin-bottom: 0;
            color: $grey-100 ;
            font-size: 16px;
            line-height: 25px;
        }

        p {
            margin-bottom: 0;
            color: $grey-900;
            font-size: 14px;
            line-height: 25px;
        }
    }

    .accordion {
        height: calc(100% - 100px);
        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px;

        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $grey-500;

        }

        .accordion-item {
            border: 0;

            .accordion-header {
                .accordion-button {
                    font-size: 16px;
                    color: $grey-100;
                    background-color: $black-500;
                    padding: 16px 20px 0px 40px;
                    box-shadow: none;
                    border-bottom-right-radius: 0.0rem;
                    border-bottom-left-radius: 0.0rem;

                    &:focus {
                        box-shadow: none;
                        border: 0;
                    }

                    &::after {
                        content: '';
                        background-image: url(../../../../public/assets/images/Icon_down.svg);
                        rotate: 0deg;
                    }
                }
            }

            .accordion-body {
                background-color: $black-500;

                .accordion-outer {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    border-bottom: 1px solid $grey-100;
                    padding: 10px 25px;
                    background-color: $black-500;
                    // padding-left: 20px;

                    img {
                        width: 20px;
                        margin-right: 10px;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: 14px;
                        color: $white-color;
                    }
                }
            }


        }


    }

    .list-icon-social-media {

        .social-icon {
            text-align: center;
            padding-top: 20px;
            margin: 0px 20px;
            cursor: pointer;
            // padding: 5px 0;


            &:focus-within {
                border: 1px solid $blue-100;
                border-radius: 5px;
                margin: 0px 20px;
                padding-top: 19px;
            }


            img {
                width: 40px;
                height: 40px;
                margin-bottom: 10px;
            }

            h5 {
                font-size: 16px;
                color: $grey-100;
                line-height: 25px;
                margin-bottom: 0;

            }

            p {
                color: $grey-900;
                font-size: 14px;
                line-height: 21px;
            }

            a {
                text-decoration: none;
            }


        }


    }

    .add-store {
        position: absolute;
        right: 40px;
        bottom: 40px;
    }

    .modal-btn {
        position: absolute;
        right: 20px;
        bottom: 20px;
        background-color: $orange-100;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        border: 0;

    }

    .modal {
        .modal-dialog {
            .modal-content {
                .modal-header {

                    background-color: $white-color;

                    h5 {


                        img {
                            padding-right: 10px;
                            color: #e70404;
                        }


                        font-size: 20px;
                        line-height: 25px;
                        color: $black-300;
                    }
                }

                .modal-body {
                    background-color: $grey-400;

                    .body-outer {
                        margin: 20px 20px 0;
                        text-align: center;
                        border: 2px dashed $grey-900;
                        padding: 10px;

                        p {
                            font-size: 14px;
                            line-height: 25px;
                            color: $black-300;
                        }
                    }



                }

                .modal-footer {
                    border: 0;
                    // background-color: $grey-400;
                    padding: 0 12px 12px;
                    justify-content: end;

                    .close-modal {
                        border: 0;
                        font-size: 16px;
                        color: $black-300;
                        line-height: 25px;
                        background-color: transparent;
                    }
                }
            }
        }
    }

}



.rightBar {
    max-width: 300px;
    width: 100%;
    // place-self: start;
    background-color: $black-300;
    min-height: calc(100vh - 110px);
    padding: 20px;
    position: relative;



    @media screen and (max-width : 992px) {
        // display: none;
        max-width: 1140px;
        width: 100%;
        height: 100vh;
        margin-bottom: 20px;

    }

    h3 {
        font-size: 20px;
        color: $grey-100;
        cursor: pointer;
        margin-bottom: 0;

        .fa {
            padding-right: 10px;
        }
    }

    .upload-box {
        text-align: center;
        border: 2px dashed $grey-500;

        padding: 20px 0;
        margin: 30px 0;

        p {
            color: $grey-100;
            font-size: 16px;
        }
    }

    .modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    background-color: $grey-400;
                    display: flex;
                    padding: 0;

                    @media screen and (max-width: 768px) {
                        display: block;
                    }

                    .list-outer-modal-1 {
                        border-right: 1px solid #8b879e1f;
                        margin-bottom: 0;
                        list-style: none;
                        padding: 0;
                        padding-bottom: 150px;
                        padding-top: 10px;

                        @media screen and (max-width: 768px) {
                            border-right: 0px solid $grey-500;
                            border-bottom: 1px solid $grey-500;
                            padding-bottom: 20px;
                        }

                        li {
                            padding: 5px 20px;

                            &:hover {
                                background-color: #2F7EDB;
                                color: $grey-100;
                            }

                            svg {
                                width: 30px;
                            }

                            .fa {
                                padding-right: 10px
                            }
                        }
                    }

                    .list-outer-modal-2 {
                        @extend .list-outer-modal-1;

                        li {
                            padding: 8px 70px 8px 20px;
                        }
                    }
                }

                .modal-footer {
                    padding-right: 30px;
                    justify-content: end;
                    margin-top: 0;

                    .cancel {
                        background-color: transparent;
                        border: 0;
                    }
                }
            }
        }
    }

    .choose-file {
        background-color: transparent;
        border: 0;
        color: $blue-100;
        font-size: 16px;

        .bi {
            padding-right: 10px;
            color: $blue-100;
            font-size: 20px;
        }
    }

    .outer-title-rightBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        h5 {
            font-size: 18px;
            color: $grey-100;
            margin-bottom: 0;
        }

        p {
            font-size: 14px;
            color: $grey-900;
            margin-bottom: 0;
        }
    }

    .outer-text {

        .details {
            overflow-y: auto;
            // height: 250px;
            height: calc(100vh - 340px);
            padding-bottom: 20px;

            @media screen and (max-width :1280px) {
                height: 100%;
            }


            &::-webkit-scrollbar {
                width: 6px;


            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: $grey-500;

            }

            h4 {
                font-size: 16px;
                color: $grey-100;
                margin-bottom: 0;
                margin-top: 15px;
            }

            p {
                font-size: 14px;
                color: $grey-100;

                margin-bottom: 0;
                opacity: 0.5;

            }

            label {
                display: block;
                font-size: 16px;
                color: $grey-100;
                line-height: 25px;
                margin-bottom: 0;
                margin-top: 15px;
            }

            input {
                background-color: transparent;
                border: 0;
                box-shadow: none;
                color: $grey-100;
                padding: 0;
                max-width: 230px;
                width: 100%;

                &:focus {
                    border-bottom: 1px solid $white-color;
                    border-radius: 0;
                }
            }

            .edit-input {
                position: relative;


                img {
                    position: absolute;
                    top: 0;
                    right: 10px;
                    width: 15px;
                }
            }
        }

    }

    .select {


        label {
            color: $blue-100;
            font-size: 16px;
            padding-top: 35px;
            margin-bottom: 10px;
            line-height: 25px;
            display: block;
            margin-bottom: 0;

            img {
                margin-right: 10px;
            }

            a {
                text-decoration: none;
                color: $blue-100;
            }

            .bi {
                padding-right: 5px;
            }

            cursor: pointer;
        }

        .select-move {
            margin-top: 20px;

            span {
                font-size: 16px;
                color: $grey-100;
                padding-left: 30px;
                transform: translateY(20px);
            }

            // .dropdown {
            //     padding-left: 30px;

            //     .btn {
            //         background-color: $black-bg;
            //         color: $grey-100;
            //         max-width: 230px;
            //         width: 100%;
            //         text-align: left;
            //         position: relative;

            //         &:focus {
            //             box-shadow: none;
            //         }

            //         &::after {
            //             content: '';
            //             position: absolute;
            //             right: 0;
            //             // top: 0;
            //             width: 30px;
            //             height: 30px;
            //             background-image: url('../../../../public/assets/images/Icon_down.svg');
            //             background-repeat: no-repeat;
            //             background-position: center;
            //         }
            //     }

            //     .dropdown-menu {
            //         padding: 0;
            //         max-width: 230px;
            //         width: 100%;

            //         .dropdown-item {
            //             padding: 10px;
            //             background-color: $black-300;

            //             &:hover {
            //                 background-color: $blue-500;
            //             }
            //         }
            //     }
            // }

            .form-select {
                background-image: url('../../../../public/assets/images/Icon_down.svg');
                width: 230px;
                margin-left: 30px;
                margin-bottom: 0;
            }

        }

        .input-add {
            position: relative;
            margin-bottom: 20px;
            margin-top: 10px;

            input {
                background-color: transparent;
                border: 0;
                padding-left: 35px;
                color: $grey-100;
                margin-top: 30px;
                max-width: 230px;
                width: 100%;

                &:focus {
                    box-shadow: none;
                    border-bottom: 1px solid $dark-grey-300;
                    border-radius: 0;

                }
            }

            ::placeholder {
                color: $blue-100;

            }

            .tag {
                position: absolute;
                top: 8px;
                left: 5px;
                color: $blue-100;
            }

            .btn_tick_green {
                position: absolute;
                top: 0;
                right: 0;
            }
        }


    }

    .check-btn {
        position: absolute;
        bottom: 35px;
        width: 90%;
        white-space: nowrap;

        @media screen and (max-width :992px) {
            width: 94%;
        }

        @media screen and (max-width :540px) {
            width: 85%;
        }

        button {
            background-color: transparent;
            // @extend .delete_button;
        }


        .theme-3 {
            width: 45%;
            text-align: center;

            @media screen and (max-width :992px) {
                width: 50%;
            }
        }

        .delete_button {
            width: 45%;
            text-align: center;
        }

        .pro-gress {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .upload-text {
                color: $grey-100;
                font-size: 16px;
                margin-bottom: 0;
            }

            .button {
                font-size: 12px;
                background-color: transparent;
                border: 0;
                color: $orange-100;
                padding-left: 40px;
                margin-bottom: 0;
                text-decoration: none;
            }
        }

        .progress {
            width: 100%;
            background-color: $black-500;
            border-radius: 50px;
            height: 10px;

            .progress-bar {
                background-color: $blue-100;
            }
        }
    }


    .title-url {
        display: flex;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            color: $grey-500;

        }
    }

    .add_web {
        margin-top: 39px;

        input {
            margin-bottom: 13px;
        }

        .form-select {
            background-image: url('../../../../public/assets/images/Icon_down.svg');
        }

        // .dropdown {

        //     .btn {
        //         background-color: $black-bg;
        //         color: $grey-100;
        //         width: 100%;
        //         text-align: left;
        //         position: relative;

        //         &:focus {
        //             box-shadow: none;
        //         }

        //         &::after {
        //             content: '';
        //             position: absolute;
        //             right: 0;
        //             // top: 0;
        //             width: 30px;
        //             height: 30px;
        //             background-image: url('../../../../public/assets/images/Icon_down.svg');
        //             background-repeat: no-repeat;
        //             background-position: center;
        //         }
        //     }

        //     .dropdown-menu {
        //         padding: 0;
        //         width: 100%;

        //         .dropdown-item {
        //             padding: 10px;
        //             background-color: $black-300;

        //             &:hover {
        //                 background-color: $blue-500;
        //             }
        //         }
        //     }
        // }

        .folder-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 50px;

            button {
                background-color: transparent;
                border: 0;
                color: $blue-100;
                font-size: 16px;

                .bi {
                    padding-right: 5px;
                }
            }
        }
    }

    .url {
        margin-top: 23px;

        h4 {
            font-size: 16px;
            line-height: 25px;
            color: $grey-100;
        }

        .link-pen {

            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
                font-size: 14px;
                color: $grey-900;
                text-decoration: none;
            }

            .bi {
                color: $blue-100;
            }

        }
    }

    .linking-icon {

        margin-top: 40px;

        h3 {
            margin-bottom: 10px;
            font-size: 16px;
            color: $grey-100;
        }

        .first {
            text-align: center;
            margin-bottom: 30px;
            padding: 5px 0;
            margin-bottom: 20px;

            &:focus-within {
                border: 1px solid $blue-100;
                border-radius: 5px;
                padding: 4px 0;
            }

            a {
                text-decoration: none;

                img {
                    width: 30px;
                    height: 30px;

                }

                p {
                    color: $grey-100;
                    font-size: 12px;
                    margin-bottom: 0;
                    padding-top: 10px;
                    white-space: nowrap;
                    padding-left: 0;
                }
            }


        }
    }

    .click-left-bar {
        position: absolute;
        top: 0;
        left: -100%;
        max-width: 300px;
        width: 100%;
        background-color: $black-300;
        min-height: calc(100vh - 110px);
        box-shadow: 1px 0px 21px #1a1919;
        z-index: 99;
        padding: 20px;

        @media screen and (max-width : 768px) {
            left: 10%;

        }

        .form-select {
            background-image: url('../../../../public/assets/images/Icon_down.svg');
        }

        // .dropdown {

        //     .btn {
        //         background-color: $black-bg;
        //         color: $grey-100;
        //         width: 100%;
        //         text-align: left;
        //         position: relative;

        //         &:focus {
        //             box-shadow: none;
        //         }

        //         &::after {
        //             content: '';
        //             position: absolute;
        //             right: 0;
        //             // top: 0;
        //             width: 30px;
        //             height: 30px;
        //             background-image: url('../../../../public/assets/images/Icon_down.svg');
        //             background-repeat: no-repeat;
        //             background-position: center;
        //         }
        //     }

        //     .dropdown-menu {
        //         padding: 0;
        //         width: 100%;

        //         .dropdown-item {
        //             padding: 10px;
        //             background-color: $black-300;

        //             &:hover {
        //                 background-color: $blue-500;
        //             }
        //         }
        //     }
        // }


        h3 {
            margin-bottom: 23px;
            font-size: 20px;
            color: $grey-100;
        }

        label {
            margin-bottom: 6px;
        }

        .save-id-btn {
            position: absolute;
            bottom: 30px;
            right: 20px;

            button {
                background-color: transparent;
                border: 0;
                color: $grey-100;
                font-size: 16px;
                padding-right: 10px;
            }

            @media screen and (max-width : 768px) {
                bottom: 10px;
            }
        }

        .btn-group {
            margin-top: 54px;

            @media screen and (max-width :768px) {
                margin-top: 20px;
            }

            .theme-cold {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                background-color: #3C446D;
                color: $grey-100;
                font-size: 16px;
            }

            .theme-button-2 {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                font-size: 16px;
                padding: 10px 23px;
            }
        }

    }

    .close-btn {
        position: absolute;
        top: 0;
        left: -40px;
        background-color: $black-300;
        height: 60px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 2px 2px 2px $black-400;

        img {
            width: 30px;
        }
    }

    .close-left-bar {
        display: none;
    }
}