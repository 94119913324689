:root {
    --white: #fff;
    --webkit-color: #fff;
    --ms-color: #fff;
    --moz-color: #fff;
    --o-color: #fff;
    --black-100: #121120;
    --webkit-color: #121120;
    --ms-color: #121120;
    --moz-color: #121120;
    --o-color: #121120;
    --black-200: #2A2220;
    --webkit-color: #2A2220;
    --ms-color: #2A2220;
    --moz-color: #2A2220;
    --o-color: #2A2220;
    --black-300: #333748;
    --webkit-color: #333748;
    --ms-color: #333748;
    --moz-color: #333748;
    --o-color: #333748;
    --black-400: #1F2229;
    --webkit-color: #1F2229;
    --ms-color: #1F2229;
    --o-color: #1F2229;
    --moz-color: #1F2229;
    --black-500: #2A2F43;
    --webkit-color: #2A2F43;
    --ms-color: #2A2F43;
    --moz-color: #2A2F43;
    --o-color: #2A2F43;
    --black-800: #565963;
    --webkit-color: #565963;
    --ms-color: #565963;
    --moz-color: #565963;
    --o-color: #565963;
    --grey-100: #E4E4ED;
    --webkit-color: #E4E4ED;
    --ms-color: #E4E4ED;
    --moz-color: #E4E4ED;
    --o-color: #E4E4ED;
    --dark-grey-300: #8A869D;
    --webkit-color: #8A869D;
    --ms-color: #8A869D;
    --moz-color: #8A869D;
    --o-color: #8A869D;
    --grey-400: #1316211A;
    --webkit-color: #1316211A;
    --ms-color: #1316211A;
    --moz-color: #1316211A;
    --o-color: #1316211A;
    --grey-500: #8B879E;
    --webkit-color: #8B879E;
    --ms-color: #8B879E;
    --moz-color: #8B879E;
    --o-color: #8B879E;
    --grey-600: #8C85A0;
    --webkit-color: #8C85A0;
    --ms-color: #8C85A0;
    --moz-color: #8C85A0;
    --o-color: #8C85A0;
    --grey-700: #E2E0F8;
    --webkit-color: #E2E0F8;
    --ms-color: #E2E0F8;
    --moz-color: #E2E0F8;
    --o-color: #E2E0F8;
    --grey-800: #B7B7CA;
    --webkit-color: #B7B7CA;
    --ms-color: #B7B7CA;
    --moz-color: #B7B7CA;
    --o-color: #B7B7CA;
    --orange-100: #EB8445;
    --webkit-color: #EB8445;
    --ms-color: #EB8445;
    --moz-color: #EB8445;
    --o-color: #EB8445;
    --blue-100: #7EB9FF;
    --webkit-color: #7EB9FF;
    --ms-color: #7EB9FF;
    --moz-color: #7EB9FF;
    --o-color: #7EB9FF;
    --blue-200: #4272b2;
    --webkit-color: #4272b2;
    --ms-color: #4272b2;
    --moz-color: #4272b2;
    --o-color: #4272b2;
    --coral-100: #F55851;
    --webkit-color: #F55851;
    --ms-color: #F55851;
    --moz-color: #F55851;
    --o-color: #F55851;
    --grey-900: #B6B1CE;
    --webkit-color: #B6B1CE;
    --ms-color: #B6B1CE;
    --moz-color: #B6B1CE;
    --o-color: #B6B1CE;
    --blue-500: #3C446D;
    --webkit-color: #3C446D;
    --ms-color: #3C446D;
    --moz-color: #3C446D;
    --o-color: #3C446D;
    --grey-200: #C2C2CE;
    --webkit-color: #C2C2CE;
    --ms-color: #C2C2CE;
    --moz-color: #C2C2CE;
    --o-color: #C2C2CE;
}

$white-color: var(--white);
$black-bg: var(--black-100);
$black-300: var(--black-300);
$black-400: var(--black-400);
$black-500: var(--black-500);
$black-800: var(--black-800);
$black-200: var(--black-200);
$grey-100: var(--grey-100);
$grey-500: var(--grey-500);
$grey-200: var(--grey-200);
$orange-100: var(--orange-100);
$blue-100: var(--blue-100);
$blue-200: var(--blue-200);
$coral-100: var(--coral-100);
$dark-grey-300: var(--dark-grey-300);
$grey-900: var(--grey-900);
$blue-500: var(--blue-500);
$grey-400: var(--grey-400);
$grey-600: var(--grey-600);
$grey-700: var(--grey-700);
$grey-800: var(--grey-800);