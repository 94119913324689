header {
    .top-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $black-300;
        width: 100%;
        height: 60px;
        padding: 10px 10px 0 30px;
        position: relative;

        .listing {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 150px;
                width: 100%;
            }

            .menu {
                #box {
                    position: absolute;

                    right: 0;
                    display: none;

                }

                .click {
                    position: absolute;
                    top: 10px;
                    right: 20px;
                    font-size: 30px;
                    color: $grey-100;
                    display: none;

                    @media screen and (max-width: 568px) {
                        display: block;
                        z-index: 4;
                    }
                }

                ul {
                    display: flex;
                    padding: 0;
                    margin-bottom: 0;
                    list-style: none;
                    padding-left: 20px;
                    position: relative;

                    @media screen and (max-width: 568px) {
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 350px;
                        height: 100vh;
                        background-color: $black-500;
                        transform: translateX(-100%);
                        transition: 0.5s all ease-in-out;
                        display: block;
                        padding: 0;
                        text-align: center;
                        z-index: 3;

                    }

                    li {

                        padding: 0 15px;

                        @media screen and (max-width: 568px) {
                            padding: 30px 0;

                        }

                        a {
                            text-decoration: none;
                            color: $grey-100;
                            padding: 0;
                            font-size: 16px;


                            &:hover {
                                color: $orange-100;
                            }


                        }

                        .active {
                            position: relative;
                            color: $orange-100;

                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: -14px;
                                width: 100%;
                                height: 2px;

                                background-color: $orange-100;
                            }
                        }


                    }


                }

                #box:checked~ul {
                    transform: translateX(0);
                }
            }
        }

        .sing-log-in {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            @media screen and (max-width: 992px) {
                display: none;
            }

            .search-bar {
                position: relative;
                width: 260px;


                ::placeholder {
                    color: $grey-100;
                    // padding-left: 10px;
                }

                img {
                    position: absolute;
                    top: 8px;
                    right: 15px;

                }

                input {
                    padding-left: 15px;
                    padding-right: 30px;
                }
            }

            .fa {
                font-size: 20px;
                color: $grey-100;
                padding: 0 5px;
            }

            .bell {
                padding: 0 10px;
                position: relative;

                img {
                    width: 18px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: -5px;
                    right: 10px;
                    width: 5px;
                    height: 5px;
                    background-color: $coral-100 ;
                    border-radius: 50px;
                    z-index: 999;
                }
            }

            .dropdown {


                .btn {
                    font-size: 15px;
                    color: $grey-100;
                    padding: 0;
                    border: 0;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    margin-right: 700px;



                    .dropdown-item {
                        background-color: $black-bg;
                        padding: 8px 30px;

                        img {
                            width: 20px;
                            margin-right: 5px;
                        }

                        &:hover {
                            background-color: $blue-500;
                        }
                    }

                    .item-2 {
                        position: relative;
                    }

                    .dropdown-2 {
                        position: absolute;
                        left: 5px;
                        top: 2px;

                        .raute {
                            rotate: 90deg;
                            font-size: 20px;

                        }

                        .menu-2 {
                            position: absolute;
                            left: -204px;
                            top: -3px;

                        }
                    }
                }
            }

            .dropdown:hover>.menu-2 {
                display: block;
            }
        }
    }
}

.second-menu {
    background-color: $black-500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-wrap: wrap;
    white-space: nowrap;
    width: 100%;
    min-height: 50px;
    padding: 0px 13px 0 30px;
    box-shadow: 0px 4px 12px #12112033;
    position: relative;

    @media screen and (max-width : 1099px) {
        padding: 0 5px;
    }


    .heading {
        h3 {
            color: $grey-100;
            font-size: 16px;
            margin-bottom: 0;
        }


    }

    .menu_second_bar {

        #right {
            position: absolute;
            right: 0;
            top: 0;
            display: none;
        }

        .click {
            position: absolute;
            right: 20px;
            top: 9px;
            font-size: 20px;
            color: $grey-100;
            display: none;

            @media screen and (max-width :992px) {
                display: block;
                z-index: 2;
            }
        }

        ul {
            display: flex;
            padding: 0;
            list-style: none;
            margin-bottom: 0;

            @media screen and (max-width :992px) {
                position: fixed;
                top: 60px;
                right: 0;
                width: 300px;
                min-height: 100%;
                background-color: $black-500;
                text-align: left;
                display: block;
                transform: translateX(100%);
                transition: 0.5s all ease-in-out;
                z-index: 1;


            }

            li {

                padding: 0 5px;

                @media screen and (max-width : 1099px) {
                    padding: 0 5px;
                }

                @media screen and (max-width :992px) {
                    padding: 20px 0;
                    padding-left: 20px;
                }

                img {
                    padding: 0 8px;
                    width: 33px;
                }

                a {
                    text-decoration: none;
                    color: $grey-100;
                    display: block;
                    font-size: 16px;
                    margin: 0;

                    &:hover {
                        color: $orange-100;

                    }
                }

                .active-2 {
                    position: relative;
                    color: $orange-100;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -13px;
                        width: 100%;
                        height: 2px;
                        background-color: $orange-100;

                        @media screen and (max-width : 992px) {
                            width: 50%;
                        }
                    }
                }


            }
        }

        #right:checked~ul {
            transform: translateX(0%);
        }

    }
}